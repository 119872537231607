import './App.css';
import logo from './Assets/logo.png'
import contact from './Assets/contact.png'
import mail from './Assets/mail.png'
import demoPic from './Assets/demoPic.png'
import tracking from './Assets/tracking.png'
import cycling from './Assets/cycling.png'
import sms from './Assets/sms.png'
import order from './Assets/order.png'
import product from './Assets/product.png'
import reporting from './Assets/reporting.png'
import store from './Assets/store.png'
import dispatch from './Assets/dispatch.png'
import paypal from './Assets/paypal.png'
import razorpay from './Assets/razorpay.png'
import cart from './Assets/cart.png'
import textLocal from './Assets/textLocal.png'
import homescreen from './Assets/homescreen.png'
import homeservice from './Assets/homeservice.png'
import orderHistory from './Assets/orderHistory.png'
import picNdrop from './Assets/picNdrop.png'
import shop from './Assets/shop.png'
import userprofile from './Assets/userprofile.png'
import cartTop from './Assets/cartTop.png'
import tip from './Assets/tip.png'
import multipay from './Assets/multipay.png'
import checkout from './Assets/checkout.png'
import pool from './Assets/pool.png'
import taxi from './Assets/taxi.png'
import home from './Assets/home.png'
import shopscreen from './Assets/shopscreen.png'
import shopdesc from './Assets/shopdesc.png'
import picdrop from './Assets/picdrop.png'
import storenear from './Assets/storenear.png'
import multiservice from './Assets/multiservice.png'
import orderhis from './Assets/orderhis.png'
import user from './Assets/user.png'
import man from './Assets/man.png'
import vendor from './Assets/vendor.png'
import driver from './Assets/driver.png'
import unknown from './Assets/unknown.png'

function App() {
  return (
    <div className="App">
      {/* header */}
      <div className='header-container'>
        <div className='logo-container'>
          <img src={logo} alt='logo' />
        </div>
        <div className='contact-container'>
          <div className='mobile-container'>
            <div className='icon-mobile'>
              <img src={contact} alt='logo' />
            </div>
            <div className='number-mobile'>
              <span>+91 9335062240</span>
              <span>24/7 Customer Support</span>
            </div>
          </div>
          <div className='mobile-container'>
            <div className='icon-mobile'>
              <img src={mail} alt='logo' />
            </div>
            <div className='number-mobile'>
              <span>tatkalservicesmau@gmail.com</span>
              <span>Sales & Support</span>
            </div>
          </div>
        </div>
      </div>
      {/* header end */}
      {/* main heading */}
      <div className='heading-container'>
        <span> <span id='ts'>Tatkal Services</span> Multipurpose App - Shopping, Grocery, Food, Cab, Pick & Drop, Home service in Single App</span>
      </div>
      {/* main heading end */}
      {/* Demo section */}
      <div className='demo-section'>
        <div className='demo-container'>
          <div className='download-section'>
            <div className='download-heading'>
              <span>Demo</span>
              <span>Apps</span>
            </div>
            <div className='download-apk-grid'>
              <div className='apk-card'>
                <img src={man} alt='apk-pic' width={80} />
                <div className='sub-head'>User App</div>
                <a href="https://tatkalservices.com/apk/User-App.apk" download>
                  <div className='apk-download'>APK Download</div>
                </a>
                <div className='apk-mobile'>Mobile 9999999990 | OTP 1234</div>
              </div>

              <div className='apk-card'>
                <img src={vendor} alt='apk-pic' width={80} />
                <div className='sub-head'>Vendor App</div>
                <a href="https://tatkalservices.com/apk/Vendor-App.apk" download>
                  <div className='apk-download'>APK Download</div>
                </a>
                <div className='apk-mobile'>Mobile 9999999991 | OTP 1234</div>
              </div>

              <div className='apk-card'>
                <img src={driver} alt='apk-pic' width={80} />
                <div className='sub-head'>Driver App</div>
                <a href="https://tatkalservices.com/apk/Driver-App.apk" download>
                  <div className='apk-download'>APK Download</div>
                </a>
                <div className='apk-mobile'>Mobile 9999999992 | OTP 1234</div>
              </div>

              <div className='apk-card'>
                <img src={unknown} alt='apk-pic' width={80} />
                <div className='sub-head'>Vendor Dashboard</div>
                <a href="https://tatkalservices.com/multisolution" target="_blank">
                  <div className='apk-download'>Login</div>
                </a>
                <div className='apk-mobile'>Mobile 7983270583 | Password admin1234</div>
              </div>

            </div>
          </div>
          <div className='demo-pic'>
            <img src={demoPic} alt='demo pic' />
          </div>
        </div>
      </div>
      {/* Demo section end */}
      {/* cover section */}
      <div className='cover-section'>
        <div className='cover-heading'>What Features We Are Providing!</div>
        <div className='cover-grid'>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#E6623935' }}>
                <img src={tracking} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Live Tracking of Order</div>
            <div className='c-paragraph'>Customer can live track the order. He can see exact location of rider on inbuilt google map. Estimated time will also be displayed.</div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#20C99735' }}>
                <img src={cycling} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Delivery Men Apps</div>
            <div className='c-paragraph'>You can create an unlimited number of delivery men and by Delivery men apps they can easily accept an order.</div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#FCC41935' }}>
                <img src={sms} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>SMS OTP integrated</div>
            <div className='c-paragraph'>Multiservice app comes with a bunch of integrated SMS Gateways. All of them are popular and you can verify your users in a smarter way.</div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#5C7CFA35' }}>
                <img src={reporting} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Reporting and Statistics</div>
            <div className='c-paragraph'>Full of statistics and reporting will help you identify your business cash flow and all the financial conditions.</div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#CD7EE835' }}>
                <img src={product} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Product Management</div>
            <div className='c-paragraph'>You can control the whole Product section by managing categories and subcategories, Attributes, Addons and more. </div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#A834F435' }}>
                <img src={order} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Advanced Order Management</div>
            <div className='c-paragraph'>Order management feature will help admin and the restaurant manager to manage every order very efficiently.</div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#628D0135' }}>
                <img src={store} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Multiple Stores</div>
            <div className='c-paragraph'>Multipurpose app is a multi store supported system. So you can add an unlimited number of stores and manage those from the admin panel easily.</div>
          </div>
          <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#B4890535' }}>
                <img src={dispatch} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Dispatch Management</div>
            <div className='c-paragraph'>Dispatch Management will help you to identify quickly which orders are ongoing and which orders are waiting for a delivery man.</div>
          </div>
        </div>
      </div>
      {/* cover section end */}
      {/* payment gateway */}
      <div className='gateway-section'>
        <div className='gateway-heading'>
          Integrated with Payment Gateways
        </div>
        <div className='gateway-pic'>
          <img src={razorpay} alt='cover-icon' />
          <img src={paypal} alt='cover-icon' />
        </div>
      </div>
      {/* payment gateway end */}
      {/* Business section */}
      <div className='cover-section'>
        <div className="siteWidth">
          <div className='business-grid'>
            <div className="business-img">
              <img src={cart} alt='' />
            </div>

            <div className="business-right">
              <div style={{ marginTop: '2rem' }} className='cover-heading'>Business Section​</div>
              <div className="business-right-inner">
                <div>
                  <div className='cover-card'>
                    <div className='cover-icon'>
                      <span style={{ backgroundColor: '#0938F435' }}>
                        <img src={checkout} alt='cover-icon' />
                      </span>
                    </div>
                    <div className='c-heading'>Fancy And Minimal checkout</div>
                    <div className='c-paragraph'>Business Settings will help you to set up your business as you wish. You will get lots of configuration to make your business unique.</div>
                  </div>
                </div>
                <div>
                  <div className='cover-card'>
                    <div className='cover-icon'>
                      <span style={{ backgroundColor: '#BB060635' }}>
                        <img src={multipay} alt='cover-icon' />
                      </span>
                    </div>
                    <div className='c-heading'>Multiple Payment Gateways</div>
                    <div className='c-paragraph'>Multipurpose app comes with a bunch of integrated payment gateway. All of them are popular and you can start your business immediately.</div>
                  </div>
                </div>
                <div>
                  <div className='cover-card'>
                    <div className='cover-icon'>
                      <span style={{ backgroundColor: '#09EAF135' }}>
                        <img src={tip} alt='cover-icon' />
                      </span>
                    </div>
                    <div className='c-heading'>Driver Tip System</div>
                    <div className='c-paragraph'>Customer can pay some tip to the driver. Its tax free and goes directly in the earning of driver. No GST is applicable on this fee.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Business section end */}
      {/* sms gateway section */}
      <div className='gateway-section'>
        <div className='gateway-heading'>
          Integrated with SMS Gateways
        </div>
        <div className='gateway-pic'>
          <img src={textLocal} alt='cover-icon' />
        </div>
      </div>
      {/* sms gateway section end*/}
      <div className='cover-section'>
        <div className="siteWidth">
          <div className='business-grid'>
            <div className="business-left business-right-inner">
              <div>
                <div className='cover-card'>
                  <div className='cover-icon'>
                    <span style={{ backgroundColor: '#F3B80535' }}>
                      <img src={pool} alt='cover-icon' />
                    </span>
                  </div>
                  <div className='c-heading'>Car Pooling</div>
                  <div className='c-paragraph'>Any driver listed on this application can do carpooling. Any user can approach any driver on the route.</div>
                </div>
              </div>
              <div>
                <div className='cover-card'>
                  <div className='cover-icon'>
                    <span style={{ backgroundColor: '#0524F835' }}>
                      <img src={taxi} alt='cover-icon' />
                    </span>
                  </div>
                  <div className='c-heading'>Taxi Service</div>
                  <div className='c-paragraph'>You can also provide taxi/cab service like ola uber. Drivers can register themselves. Customers can find and book nearest available driver based upon their acceptance.</div>
                </div>
              </div>
              <div>
                <div className='cover-card'>
                  <div className='cover-icon'>
                    <span style={{ backgroundColor: '#DB70C235' }}>
                      <img src={home} alt='cover-icon' />
                    </span>
                  </div>
                  <div className='c-heading'>Home Service</div>
                  <div className='c-paragraph'>User can book home service like electrician , Plumber, Carpainter, etc on demand. Customer can get those services fulfilled by the nearest available service executive.</div>
                </div>
              </div>
            </div>
            <div className="business-right ">
              <img src={homescreen} alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='cover-section'>
        <div className='business-grid'>
          <img src={shop} alt='' />
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#007F7435' }}>
                  <img src={shopscreen} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Shop Screen</div>
              <div className='c-paragraph'>Customer can choose shop category and find all the list of shops under that. He can tap on the nearest and highest rated shop and browse its products category wise. There are two types of product category menus, one Horizontal menu on top, and one floating vertical menu on bottom.</div>
            </div>
          </div>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#04F81535' }}>
                  <img src={shopdesc} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Shop Discription</div>
              <div className='c-paragraph'>On any store/shop screen you'll find all related information like shop name, shop discription, shop timings, distance from customer, picture etc. Based upon those provided information , customer can take action.</div>
            </div>
          </div>
        </div>
      </div>
      <div className='cover-section'>
        <div className='business-grid'>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#0938F435' }}>
                  <img src={checkout} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Minimal Checkout</div>
              <div className='c-paragraph'>This is out checkout screen. Its minimal, and informatic. Customer can edit quantity of products, he can reconcile the order , he can provide his delivery preference.</div>
            </div>
          </div>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#09EAF135' }}>
                  <img src={tip} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Driver Tip</div>
              <div className='c-paragraph'>Customer can pay some tip to the driver. Its taxfree and goes directly in the earning of driver. No GST is applicable on this fee.</div>
            </div>
          </div>
          <img src={cartTop} alt='' />
        </div>
      </div>
      <div className='cover-section'>
        <div className='business-grid'>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#B98F1635' }}>
                  <img src={picdrop} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Pick n Drop</div>
              <div className='c-paragraph'>Customer can get any item picked from anywhere and get it dropped to desired location. Delivery guy will pick the parcel and deliver it to the mentioned drop location.</div>
            </div>
          </div>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#102EAB35' }}>
                  <img src={storenear} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Shop from nearby store</div>
              <div className='c-paragraph'>Customer can provide name of shop and address along with location. Also provide the items you want to shop from it, Or call the shop directly and give your order. Our delivery guy will reach there within minutes and pick the order and get it delivered to you.</div>
            </div>
          </div>
          <img src={picNdrop} alt='' />
        </div>
      </div>
      <div className='cover-section'>
        <div className='business-grid'>
          <img src={homeservice} alt='' />
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#DB72C335' }}>
                  <img src={home} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Home service</div>
              <div className='c-paragraph'>Home services like Electrician, Plumber, Carpenter, etc can be booked from this app. Request will directly go to the vendor. He'll accept or deny, If denied it will jump to another vendor, If accepted vendor will come the do the repair.</div>
            </div>
          </div>
          <div> <div className='cover-card'>
            <div className='cover-icon'>
              <span style={{ backgroundColor: '#5E037535' }}>
                <img src={multiservice} alt='cover-icon' />
              </span>
            </div>
            <div className='c-heading'>Multi service providers</div>
            <div className='c-paragraph'>There can be unlimited categories and unlimited vendors/service providers.</div>
          </div></div>
        </div>
      </div>
      <div className='cover-section'>
        <div className='business-grid'>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#060FED35' }}>
                  <img src={orderhis} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Order history</div>
              <div className='c-paragraph'>Orders are categories into two sections, product orders and service orders. You can see on top of order history screen. Widget display all information about order.</div>
            </div>
          </div>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#C11C1B35' }}>
                  <img src={multipay} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>Easy payments</div>
              <div className='c-paragraph'>Customer can place COD order and he can pay online from order section using various payment modes. No hassles of keeping change.</div>
            </div>
          </div>
          <img src={orderHistory} alt='' />
        </div>
      </div>
      <div className='cover-section'>
        <div className='business-grid'>
          <div>
            <div className='cover-card'>
              <div className='cover-icon'>
                <span style={{ backgroundColor: '#D930F135' }}>
                  <img src={user} alt='cover-icon' />
                </span>
              </div>
              <div className='c-heading'>User Profile</div>
              <div className='c-paragraph'>User profile is minimal and creative. Customer can navigate easily. He can update his profile, go to recent orders, jump to saved addresses, ewallet, refern n earn, Logout, he can contact support via chat, call, email</div>
            </div>
          </div>
          <img src={userprofile} alt='' />
        </div>
      </div>
      <div className='footer-section'>
        <div className='footer-contact'>
          <div className='logo-container'>
            <img src={logo} alt='logo' />
          </div>
          <div className='contact-container'>
            <div className='mobile-container'>
              <div className='icon-mobile'>
                <img src={contact} alt='logo' />
              </div>
              <div className='number-mobile'>
                <span>+91 9335062240</span>
                <span>24/7 Customer Support</span>
              </div>
            </div>
            <div className='mobile-container'>
              <div className='icon-mobile'>
                <img src={mail} alt='logo' />
              </div>
              <div className='number-mobile'>
                <span>tatkalservicesmau@gmail.com</span>
                <span>Sales & Support</span>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-copyright'>
          <span>&#169; copyright 2022 |   All rights reserved</span>
          <span>Tatkal Service</span>
        </div>
      </div>
    </div>
  );
}

export default App;
